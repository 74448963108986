.variable-list {
  margin: 0 -0.5rem;
  height: calc(100% - 4rem);
  overflow-y: auto;
  padding-bottom: 6rem;
}

.variable-item {
  background-color: white;
  border-radius: 4px;
}

.variable-item code {
  font-size: 0.875rem;
  background: none;
  padding: 0;
}

.variable-item .btn-link {
  opacity: 0.5;
}

.variable-item:hover .btn-link {
  opacity: 1;
}

.text-truncate {
  background-color: #f8f9fa;
}

.btn-link:hover {
  opacity: 0.8;
}

.modal-content {
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.modal-custom {
  width: 672px;
}

code {
  background-color: rgba(0,0,0,.03);
  padding: 0.2rem 0.4rem;
  border-radius: 0.2rem;
}

.custom-shining-gold {
  padding: 1px 0;
  color: gold;
  text-shadow: 0 0 10px gold, 0 0 20px gold, 0 0 30px gold;
  animation: shine 1.5s ease-in-out infinite alternate;
}

.gold-spinner {
  color: gold;
  text-shadow: 0 0 10px gold, 0 0 20px gold, 0 0 30px gold;
}

@keyframes shine {
  0% {
    transform: scale(0.9) rotate(-3deg);
    text-shadow: 0 0 10px gold, 0 0 20px gold, 0 0 30px gold;
  }

  50% {
    transform: scale(1.1) rotate(3deg);
    text-shadow: 0 0 30px gold, 0 0 50px gold, 0 0 80px gold;
  }

  100% {
    transform: scale(1) rotate(0deg);
    text-shadow: 0 0 20px gold, 0 0 40px gold, 0 0 60px gold;
  }
}

.transition-width {
  overflow: hidden;
  white-space: nowrap;
}

.tiptap-container {
  display: flex;
  justify-content: center;
  padding: 16px;
  background: #f5f5f5;
  min-height: 100vh;
  flex-direction: column;

  .reactjs-tiptap-editor {
    border-radius: 8px !important;
  }

  .richtext-relative:has(.ProseMirror) {
    height: calc(100vh - 9rem);
    overflow-y: auto;
  }

  .ProseMirror {
    max-width: 210mm; // A4 width
    width: 100%;
    min-height: 297mm !important; // A4 height
    margin: 16px auto;
    background: white;
    padding: 20mm;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-bottom: 32px;
  }
  .richtext-outline {
    outline-width: 0px;
    border-radius: 8px;
  }
}
